import React from "react";
import Layout from "../../components/Layout/Layout";
import {theme} from "../../helpers/theme";
import HomePage from "../../components/Page/Home/HomePage";
import {getHomePageData} from "../../helpers/utils";
import {BANNER_MAIN_PAGE_TYPE, BANNER_TEXTS} from "../../helpers/common";


const Phome = ({ vacancies, profile }) => {
  return (
    <>
      <Layout isOrangeLogo={false} backgroundColor={theme.colors.gray}>
        <HomePage vacancies={vacancies} profile={profile}
                  bannerText={BANNER_TEXTS ? BANNER_TEXTS[BANNER_MAIN_PAGE_TYPE] : {}}/>
      </Layout>
    </>
  )
}

export async function getServerSideProps(context) {

  return getHomePageData(context)
}

export default Phome;
